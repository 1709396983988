<template>
  <Component
    :is="typeof image === 'object' && image.provider === 'static' ? 'img' : LazyNuxtPicture"
    class="c-image"
    v-bind="bindings"
  />
</template>

<script setup lang="ts">
const LazyNuxtPicture = resolveComponent('LazyNuxtPicture')

type NuxtImage = {
  src: string;
  provider: 'cloudinary' | 'unsplash' | 'static';
  // options?: Record<string, any>
  modifiers?: Record<string, any>
  options?: Record<string, any>
};

const props = defineProps({
  image: {
    type: [String, Object as () => NuxtImage],
    default: null
  },
  alt: { type: String, default: undefined },
  title: { type: String, default: undefined },
  contain: { type: Boolean, default: undefined },
  background: { type: Boolean, default: false },
  delay: { type: Number, default: 0 },

  preload: { type: Boolean, default: undefined },
  loading: { type: String, default: 'lazy' },

  format: { type: String, default: undefined },
  sizes: { type: String, default: undefined },

  options: { type: Object, default: () => ({}) },
  modifiers: { type: Object, default: () => ({}) },
})

const filterNullFromObject = (obj) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))

const bindings = computed(() => {
  const localBindings = {
    // is: LazyNuxtPicture,
    src: props.image,
    style: {
      ...(props.options && 'style' in props.options ? props.options.style : {}),
      animationDelay: props.delay + 's',
    },
    title: props.title,
    class: {
      contain: props.contain,
      background: props.background,
    },
    alt: props.alt,
    preload: props.preload,
    fit: props.format === 'gif' ? 'coverLimit' : undefined,
    sizes: props.format === 'gif' ? undefined : props.sizes,
    format: props.format,
    loading: props.loading,
    modifiers: props.modifiers,
    provider: '',
    placeholder: true,
    ...props.options,
  }
  if (typeof props.image === 'object') {
    // if (props.image?.provider === 'static') localBindings.is = 'img'
    // if (props.image.options)
    //   localBindings = { ...localBindings, ...props.image.options }
    localBindings.src = props.image.src
    if (props.image.modifiers)
      localBindings.modifiers = {
        ...localBindings.modifiers,
        ...filterNullFromObject(props.image.modifiers)
      }
    localBindings.provider = props.image.provider
  }
  return localBindings
})
</script>

<style lang="scss">
.c-image {
  display: flex;

  transition: filter 0.3s ease;

  // &:not(.c-image-loaded) {
  //   filter: blur(5px);
  // }
  object-fit: cover;

  > img {
    width: auto;
    object-fit: inherit;
    object-position: inherit;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  &.contain img {
    object-fit: contain;
  }

  &.image-float-left,
  &.image-float-right {
    width: 5rem;
    height: 5rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  }
  &.image-float-left {
    margin-right: 1.5rem;
    float: left;
  }
  &.image-float-right {
    float: right;
    margin-left: 1.5rem;
  }

  &.content-image {

  }
}

</style>
